<template>
  <section class="mess-accordion">
    <div class="container">
      <!-- Should be an H2 -->
      <slot name="head" />
      <ul class="accordion-list">
        <!-- Should be a v-for of MessFragmentsAccordionItem -->
        <slot name="items" />
      </ul>
    </div>
  </section>
</template>
